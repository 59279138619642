import { LanguageText } from "../../utils/LanguageText";
import { useEffect, useState } from "react";
import styles from "./AnalysisPanel.module.css";

interface Props {
    activeCitation: string | undefined;
}

export const AnalysisPanel = ({ activeCitation }: Props) => {    
    const [iframeKey, setIframeKey] = useState<number>(0);

    useEffect(() => {
        if (!window.navigator.userAgent.includes("Edge") && !window.navigator.userAgent.includes("Chrome") && !window.navigator.userAgent.includes("Safari")) {
            return;
        }
        setIframeKey(iframeKey + 1)
    }, [activeCitation])

    return (
        <div className={styles.sourceContainer}>
            <iframe key={iframeKey} title={LanguageText.SOURCE} src={activeCitation} width="100%" />
        </div>
    );
};

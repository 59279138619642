
export const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minWidth: 200,
      border: '1px solid lightgrey',
      boxShadow: 'none',
      // height: '30px',
      '&:hover': {
        border: '1px solid lightgrey', // Ensure border color remains unchanged on hover
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'transparent', // Remove hover effect on menu background
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#007ACA' : 'white', // Change background color for selected option
      '&:hover': {
        backgroundColor: state.isSelected ? '#007ACA' : '#DCEEFA', // Change background color on hover, except for selected option
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'black',
    })
  };
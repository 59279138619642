import { useEffect, useMemo, useState } from "react";
import { Stack } from "@fluentui/react";

import styles from "./Answer.module.css";
import { ConversationItem, AnswerSaveRequest, saveAnswer } from "../../api";
import { parseAnswer } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { LanguageText } from "../../utils/LanguageText";
import AnswerText from "./AnswerText";
import AnswerCitation from "./AnswerCitation";
import { RatingSystem } from "../RatingSystem";
import { setAnswerRating } from "../../api";
import { AnswerRatingRequest } from "../../api";

interface Props {
    conversationItem: ConversationItem;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (url: string) => void;
    onFollowupQuestionClicked: (question: string) => void;
    showFollowupQuestions: boolean;
    selectedCategory : string;
    answerIndex: number;
}

export const Answer = ({
    conversationItem: conversationItem,
    isSelected,
    isStreaming,
    onCitationClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    selectedCategory,
    answerIndex
}: Props) => {
    const followupQuestions = conversationItem.response.choices[0].context.followup_questions;
    const messageContent = conversationItem.response.choices[0].message.content;
    const parsedAnswer = useMemo(() => parseAnswer(messageContent, isStreaming), [conversationItem]);
    const [citationSelected, setCitationSelected] = useState(-1);
    const [answerCompleted, setAnswerCompleted] = useState(false);


    const onAnswerRated = (rating: number) => {
        const request: AnswerRatingRequest = {
            conversationId: conversationItem.conversationId,
            questionId: conversationItem.questionId,
            rating: rating
        };

        setAnswerRating(request);
    };

    useEffect(() => {
        if (isStreaming || conversationItem.questionId === "" || answerCompleted) {
            return;
        }
        const sourceScores = conversationItem.response.choices[0].context.sourceScores;
        const averageSourceScore = sourceScores.reduce((sum, num) => sum + num, 0) / sourceScores.length;

        const request: AnswerSaveRequest = {
            tenantId: String(sessionStorage.getItem("tenant_id")),
            conversationId: conversationItem.conversationId,
            questionId: conversationItem.questionId,
            answer: conversationItem.response.choices[0].message.content,
            question: conversationItem.question,
            latency: conversationItem.latency,
            sourceScore: averageSourceScore,
            selectedCategory: selectedCategory
        };
        saveAnswer(request).then(() => {
            setAnswerCompleted(true);
        });
    }, [isStreaming]);

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack className={styles.answerHeader} horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div className={styles.questionInputButtonsContainer}>
                        <RatingSystem starCount={5} onRating={(rating: number) => onAnswerRated(rating)} hidden={!answerCompleted} />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow className={styles.answerText}>
                <AnswerText
                    parsedAnswer={parsedAnswer}
                    onCitationClicked={onCitationClicked}
                    citationSelected={citationSelected}
                    setCitationSelected={setCitationSelected}
                    answerSelected={isSelected}
                    isStreaming={isStreaming}
                    answerIndex={answerIndex}
                ></AnswerText>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item className={styles.citationFontSize}>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>{LanguageText.SOURCES}:</span>
                        {parsedAnswer.citations.map((citation, index) => {
                            return (
                                <AnswerCitation
                                    index={index}
                                    citation={citation}
                                    onCitationClicked={onCitationClicked}
                                    citationSelected={citationSelected}
                                    setCitationSelected={setCitationSelected}
                                    answerSelected={isSelected}
                                />
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};

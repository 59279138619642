import { ReactNode } from 'react';
import styles from "./NoAccess.module.css";
import warningLogo from "../../assets/warning.svg";

const NoAccess = ({ title, description }: { title: string; description: ReactNode }) => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"} />
            <div className={styles.parentContainer}>
                <div className={styles.centeredDiv}>
                    <img src={warningLogo} alt="Warning" className={styles.warningLogo} />
                    <h3 className={styles.title}>{title}</h3>
                    <div className={styles.description}>{description}</div>
                </div>
            </div>
        </div>
    );
};

export default NoAccess;

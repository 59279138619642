import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import nmbrsLogo from "../../assets/logo.png";
import styles from "./Layout.module.css";
import { FreeTrialRequest, getIsFreeTrial } from "../../api";
import { DropdownSwitcher } from "../../components/DropdownIndexSwitcher/DropdownSwitcher";

type LayoutProps = {
    category: { item: string, label: string };
    setCategory: React.Dispatch<React.SetStateAction<{ item: string, label: string }>>;
    isStreaming: boolean
    isLoading: boolean

};

const Layout = ({  category, setCategory, isStreaming, isLoading }: LayoutProps): JSX.Element => {
    const [isFreeTrial, setIsFreeTrial] = useState<boolean | null>(null);

    const getLogoUrl = () => {
        return String(sessionStorage.getItem("env_logo"));
    };

    const fetchFreeTrial = async () => {
        try {
            const request: FreeTrialRequest = {
                tenantId: getTenantId()
            };
            const result = await getIsFreeTrial(request);
            setIsFreeTrial(result.freeTrial);
        } catch (e) {
            setIsFreeTrial(false);
        }
    };

    useEffect(() => {
        fetchFreeTrial().catch(() => { });
    }, []);

    const getTenantId = () => {
        return String(sessionStorage.getItem("tenant_id"));
    };

    const GetImgSrc = () => {
        if (isFreeTrial) {
            return <img src={nmbrsLogo} onClick={() => window.location.reload()} alt="Nmbrs logo" aria-label="Nmbrs" />;
        }

        return <img src={`data:image/png;base64,` + `${getLogoUrl()}`} onClick={() => window.location.reload()} alt="Logo" />;
    };

    return (
        <div className={styles.layout}>
            {isFreeTrial == null ? (
                <div className={styles.emptyHeader} role="banner">
                    <div className={styles.headerContainer}></div>
                </div>
            ) : (
                <div className={isFreeTrial ? styles.header : styles.tenant_header} style={{ backgroundColor: String(sessionStorage.getItem("header_color")) }} role="banner">
                    <div className={styles.headerContainer}>
                        <h3 className={styles.headerTitle}>{GetImgSrc()}</h3>

                        <div className={styles.dropdownContainer}>
                            <DropdownSwitcher category={category} setCategory={setCategory} isStreaming={isStreaming} isLoadingStream={isLoading}/>
                        </div>
                    </div>
                </div>
            )}

            <Outlet />
        </div>
    );
};

export default Layout;

import { useRef, useState, useEffect } from "react";
import styles from "./Chat.module.css";
import { chatApi, getIsFreeTrial, FreeTrialRequest, getTenantInfo, TenantRequest, ResponseMessage, ChatAppResponse, ChatAppRequest, ChatAppResponseOrError, ConversationItem } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { ClearChatButton } from "../../components/ClearChatButton";
import { ImageLoader } from "../../components/SupportingContent/ImageLoader"
import aiLogo from "../../assets/ai_face_with_background.svg";
import { LanguageText } from "../../utils/LanguageText";
import readNDJSONStream from "ndjson-readablestream";

interface ChatProps  {
    isStreaming: boolean;
    setIsStreaming: React.Dispatch<React.SetStateAction<any>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<any>>;
    category: { item: string, label: string };
    setCategory: React.Dispatch<React.SetStateAction<{ item: string, label: string }>>;
};


const Chat = ({ category, setCategory, isStreaming, setIsStreaming, isLoading, setIsLoading }:ChatProps) => {

    const SHOULD_STREAM = true;

    const USE_SEMANTIC_CAPTIONS = false;
    const USE_SUGGEST_FOLLOWUP_QUESTIONS = false;
    const USE_OID_SECURITY_FILTER = false;
    const USE_GROUPS_SECURITY_FILTER = false;   
    const VECTOR_FIELD_LIST = ["embedding"];
    const USE_GPT4V = false;
    const GPT4V_INPUT = "textAndImages";

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);



    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(-1);
    const [conversationItems, setConversationItems] = useState<ConversationItem[]>([]);
    const [questions, setQuestions] = useState<string[]>([]);

    const [displayName, setDisplayName] = useState<string | null>(null);
    const [isFreeTrial, setIsFreeTrial] = useState<boolean | null>(null);

    const conversationId = useRef<string>("");

    const getTenantId = () => {
        return String(sessionStorage.getItem("tenant_id"));
    };

    const getAccessToken = () => {
        return String(sessionStorage.getItem("access_token"));
    };

    const getUserId = () => {
        return String(sessionStorage.getItem("user_id"));
    };

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setSelectedAnswer(-1);

        try {
            const messages: ResponseMessage[] = conversationItems.flatMap(ci => [
                { content: ci.question, role: "user" },
                { content: ci.response.choices[0].message.content, role: "assistant" }
            ]);
            const request: ChatAppRequest = {
                messages: [...messages, { content: question, role: "user" }],
                stream: SHOULD_STREAM,
                context: {
                    overrides: {
                        include_category: category.item,
                        semantic_captions: USE_SEMANTIC_CAPTIONS,
                        suggest_followup_questions: USE_SUGGEST_FOLLOWUP_QUESTIONS,
                        use_oid_security_filter: USE_OID_SECURITY_FILTER,
                        use_groups_security_filter: USE_GROUPS_SECURITY_FILTER,
                        vector_fields: VECTOR_FIELD_LIST,
                        use_gpt4v: USE_GPT4V,
                        gpt4v_input: GPT4V_INPUT
                    }
                },
                session_state: conversationItems.length ? conversationItems[conversationItems.length - 1].response.choices[0].session_state : null,
                accessToken: getAccessToken(),
                tenantId: getTenantId(),
                conversationId: conversationId.current
            };
            setQuestions([...questions, question]);
            const response = await chatApi(request);
            
            if (!response.body) {
                throw Error("No response body");
            }

            if (conversationId.current === "") {
                conversationId.current = response.headers.get("conversationId") || "";
            }
            const questionId = response.headers.get("questionId") || "";
            const latency: number = parseFloat(response.headers.get("latency") || "0");

            if (SHOULD_STREAM) {
                const parsedResponse: ChatAppResponse = await handleAsyncRequest(question, conversationItems, setConversationItems, response.body, questionId, latency);
                const conversationItem = {
                    question: question,
                    questionId: questionId,
                    response: parsedResponse,
                    latency: latency,
                    conversationId: conversationId.current
                };
                setConversationItems([...conversationItems, conversationItem]);
            } else {
                const parsedResponse: ChatAppResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                const conversationItem = {
                    question: question,
                    questionId: questionId,
                    response: parsedResponse as ChatAppResponse,
                    latency: latency,
                    conversationId: conversationId.current
                };
                setConversationItems([...conversationItems, conversationItem]);
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAsyncRequest = async (question: string, conversationItems: ConversationItem[], setConversationItems: Function, responseBody: ReadableStream<any>, questionId: string, latency: number) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {} as ChatAppResponse;

        const updateState = (newContent: string) => {
            return new Promise(resolve => {
                setTimeout(() => {
                    answer += newContent;
                    const latestResponse: ChatAppResponse = {
                        ...askResponse,
                        choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
                    };
                    const conversationItem = {
                        question: question,
                        questionId: questionId,
                        response: latestResponse,
                        latency: latency,
                        conversationId: conversationId.current
                    };
                    setConversationItems([...conversationItems, conversationItem]);
                    resolve(null);
                }, 33);
            });
        };
        try {
            setIsStreaming(true);
            for await (const event of readNDJSONStream(responseBody)) {
                if (event["choices"] && event["choices"][0]["context"] && event["choices"][0]["context"]["data_points"]) {
                    event["choices"][0]["message"] = event["choices"][0]["delta"];
                    askResponse = event as ChatAppResponse;
                } else if (event["choices"] && event["choices"][0]["delta"]["content"]) {
                    setIsLoading(false);
                    await updateState(event["choices"][0]["delta"]["content"]);
                } else if (event["choices"] && event["choices"][0]["context"]) {
                    // Update context with new keys from latest event
                    askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
                } else if (event["error"]) {
                    throw Error(event["error"]);
                }
            }
        } finally {
            setIsStreaming(false);
        }
        const fullResponse: ChatAppResponse = {
            ...askResponse,
            choices: [{ ...askResponse.choices[0], message: { content: answer, role: askResponse.choices[0].message.role } }]
        };
        return fullResponse;
    };


    const FreeTrialRequest = async () => {
        try {
            const request: FreeTrialRequest = {
                tenantId: getTenantId()
            };
            const result = await getIsFreeTrial(request);
            setIsFreeTrial(result.freeTrial);
            return result.freeTrial;
        } catch (e) {
            setIsFreeTrial(false);
            return false;
        }
    };

    useEffect(() => {
        const fetchFreeTrial = async () => {
            return await FreeTrialRequest();
        };

        fetchFreeTrial().catch(() => { });
    }, []);

    useEffect(() => {
        if (isFreeTrial == null) {
            return;
        }
        if (isFreeTrial) {
            setDisplayName("Nmbrs");
            return;
        }
        const fetchTenant = async () => {
            const tenantRequest: TenantRequest = {
                tenantId: getTenantId(),
                accessToken: getAccessToken(),
                userId: getUserId()
            };
            return await getTenantInfo(tenantRequest);
        };

        fetchTenant().then(result => {
            setDisplayName(result.displayName);
        });
    }, [isFreeTrial]);

    useEffect(() => {
        if (displayName !== null) {
            document.title = `${displayName} Knowledge Assistant`;
        }
    }, [displayName]);

    const clearChat = () => {
        if (isLoading || isStreaming) return;
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setConversationItems([]);
        conversationId.current = "";
    };
    useEffect(() => {
        clearChat()
    }, [category]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [conversationItems]);

    const onExampleClicked = (example: string) => {
        makeApiRequest(example).catch(() => { });
    };

    const onShowCitation = (citation: string, index: number) => {
        setSelectedAnswer(index);
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        setSelectedAnswer(index);
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

    };

    const openFeedbackForm = () => {
        window.open("https://nmbrsbv.typeform.com/to/rnnHVnND", "_blank");
    };

    const isMobileDevice = () => {
        return (
            window.navigator.userAgent.includes("iPhone") ||
            window.navigator.userAgent.includes("Android") ||
            window.navigator.userAgent.includes("iPad") ||
            window.navigator.userAgent.includes("webOS")
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <ImageLoader src={aiLogo} alt="Nmbrs logo" aria-label="Nmbrs" width="60px" height="60px" />
                            {displayName == null ? <h1 className={styles.chatEmptyStateTitle}>Knowledge Assistant</h1> : <h1 className={styles.chatEmptyStateTitle}>{displayName} Knowledge Assistant</h1>}
                            <h2 className={styles.chatEmptyStateSubtitle}>{category.label + LanguageText.EMPTY_STATE_SUBTITLE}</h2>
                            <ExampleList selectedCategory={category.item} onExampleClicked={onExampleClicked} />
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {conversationItems.map((conversationItem, index) => (
                                <div key={index}>
                                    <UserChatMessage message={conversationItem.question} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            key={index}
                                            conversationItem={conversationItem}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            isStreaming={isStreaming}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            showFollowupQuestions={USE_SUGGEST_FOLLOWUP_QUESTIONS && conversationItems.length - 1 === index}
                                            selectedCategory={category.item}
                                            answerIndex={index}
                                        />
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}
                </div>

                {activeAnalysisPanelTab && (
                    <AnalysisPanel
                        activeCitation={activeCitation}
                    />
                )}

                <div className={styles.chatFooter}>
                    <div className={styles.commandsContainer}>
                        <ClearChatButton className={styles.clearChat} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading || isStreaming} />

                        <div className={styles.chatInput}>
                            <QuestionInput
                                clearOnSend
                                placeholder="Stel je vraag hier (bv. Wanneer kan ik LKV oudere werknemer aanvragen?)"
                                disabled={isLoading || isStreaming}
                                onSend={question => makeApiRequest(question)}
                            />
                        </div>
                    </div>

                    <div className={styles.chatDisclaimer}>
                        <p>
                            Samen leren we sneller met deze AI-aangedreven Knowledge Assistant. Fouten en verrassingen zijn mogelijk, dus controleer de bronnen!{" "}
                            <a onClick={openFeedbackForm}>Feedback hier</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;

import { useState } from 'react';

export const ImageLoader = ({ src, alt, width, height}: { src: string, alt: string, width: string, height: string }) => {
  const [isLoading, setIsLoading] = useState(true);

return (
    <>
        {isLoading && <div style={{ width: width, height: height }}></div>}
        <img
            src={src}
            alt={alt}
            onLoad={() => setIsLoading(false)}
            style={{ display: isLoading ? 'none' : 'block' }}
            width={width}
            height={height}
        />
    </>
);
};
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";
import { parsedAnswer } from "./AnswerTypes";
import { getCitationFilePath } from "../../api";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { useEffect } from "react";

interface AnswerTextProps {
    parsedAnswer: parsedAnswer;
    onCitationClicked: (url: string) => void;
    citationSelected: number;
    setCitationSelected: React.Dispatch<React.SetStateAction<number>>;
    answerSelected: boolean | undefined;
    isStreaming: boolean;
    answerIndex: number;
}

const AnswerText = ({ parsedAnswer, onCitationClicked, citationSelected, setCitationSelected, answerSelected, isStreaming, answerIndex }: AnswerTextProps) => {
    setCitationSelected(answerSelected ? citationSelected : -1);
    
    const handleClick = (path: string, index: number) => {
        onCitationClicked(getCitationFilePath(path));
        setCitationSelected(citationSelected !== index ? index : -1);
    };

    useEffect(() =>{
        // Add onclick attribute to <a> elements
        const answerText = document.querySelector(`#answer-${answerIndex}`);
        if (answerText) {
            const anchorElements = answerText.getElementsByTagName("a");
            for (const anchorElement of anchorElements) {
                anchorElement.onclick = () => {
                    // Handle click event here
                    handleClick(anchorElement.title, parseInt(anchorElement.innerText) - 1);
                };
            }
        }
    }, [isStreaming, citationSelected]);

    return (
        <div id={`answer-${answerIndex}`} className={styles.answerText}>
            <ReactMarkdown children={DOMPurify.sanitize(parsedAnswer.answerHtml)} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />            
        </div>
    );
};

export default AnswerText;

import { getExamplesByCategory } from "../../api";
import { Example } from "./Example";
import React, { useState, useEffect } from "react";
import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};


interface Props {
    onExampleClicked: (value: string) => void;
    selectedCategory: string
}

export const ExampleList = ({ onExampleClicked,selectedCategory }: Props) => {
    const [examples, setExamples] = useState<ExampleModel[]>([]);
    const fetchExamples = async () => {
        try {
            const examplesData = await getExamplesByCategory(selectedCategory);
            setExamples(examplesData);
        } catch (error) {
            console.error("Failed to fetch examples:", error);
        }
    };
    useEffect(() => {
        fetchExamples();
    }, [selectedCategory]);
    return (
        <ul className={styles.examplesNavList}>
            {examples.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
